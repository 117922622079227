import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "antd";

import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import { useWindowSize } from "hooks/useWindowSize";
import Modal from "components/Modal";
import { Paragraph, Text } from "components/Typography";
import { userService } from "services";
import { selectModalData } from "redux/Modal/selectors";
import {
  MODAL_SIGNUP_ACTIVATE,
  MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_SIGNUP,
  MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_LOGIN
} from "redux/Modal/actions";
import { useTypedSelector } from "redux/rootReducer";
import notification from "utils/notification";
import { AnalyticServices } from "utils/analytics";
import { SEGMENT_EVENT } from "constants/segment";
import { Api } from "services/api";

const { useBreakpoint } = Grid;

function trackResendActivationEmailClick(sendNumber: number | null) {
  AnalyticServices.track(
    SEGMENT_EVENT.CLICK_RESEND_ACTIVATION_EMAIL,
    {
      "Send number": sendNumber,
    },
    {
      source: "talent",
    },
  );
}

interface IProps {
  closable?: boolean;
  show: boolean;
  onOk?(): void;
  onCancel(): void;
}

export const SignUpActivateAccountModal: React.FC<IProps> = ({
  closable = true,
  show,
  onOk,
  onCancel,
}) => {
  const screens = useBreakpoint();
  const isMobile = screens["xs"];
  const { height } = useWindowSize();
  const activateAccountModalData: {
    email: string;
    name: string;
    userId: number;
    activationCodeVersion: number;
    context: string;
  } | null = useTypedSelector((state) => selectModalData(state, MODAL_SIGNUP_ACTIVATE));

  const [activationCodeVersion, setActivationCodeVersion] = useState(
    activateAccountModalData?.activationCodeVersion ?? 0,
  );

  useEffect(() => {
    setActivationCodeVersion(activateAccountModalData?.activationCodeVersion ?? 0);
  }, [activateAccountModalData?.activationCodeVersion]);

  const handleCancel = useCallback(async () => {
    onCancel();
  }, [onCancel]);

  const renderTitle = () => {
    return <Paragraph preset="semibold20">Activate Your Account</Paragraph>;
  };

  const handleResendEmailConfirmation = useCallback(async () => {
    // TODO: Pass these values via modal state
    if (!activateAccountModalData) return;

    trackResendActivationEmailClick(activationCodeVersion);

    const responseEmail = await userService.resendEmailConfirmation(activateAccountModalData);

    if (Api.isOKResponse(responseEmail)) {
      setActivationCodeVersion(responseEmail.response.newActivationCodeVersion);
      notification.success({
        message: "A new activation link has been sent. Please check your email.",
      });
    } else {
      notification.error({
        message: "There was an error sending the activation link, please try again.",
      });
    }
  }, [activateAccountModalData, activationCodeVersion]);

  if (!show) return null;

  const closeIcon = activateAccountModalData?.context === MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_SIGNUP ? (
    <CloseIcon width={isMobile ? 24 : 28} height={isMobile ? 24 : 28} />
  ): <></>;

  return (
    <Modal
      className="sign-up-modal compact"
      closable={closable}
      title={renderTitle()}
      visible={show}
      onOk={onOk}
      onCancel={handleCancel}
      maskClosable={false}
      closeIcon={closeIcon}
      centered
      width={540}
      bodyStyle={{ maxHeight: isMobile ? (height || 0) - 182 : "unset" }}
      footer={null}
    >
      <Paragraph className="opacity--08 m__t--4" preset={isMobile ? "regular14" : "regular16"}>
        {activateAccountModalData?.context === MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_SIGNUP && (
          <>
            Thanks for signing up! We sent you a confirmation email
            {activateAccountModalData?.email ? (
              <>
                {" "}
                to <Text preset="semibold16">{activateAccountModalData?.email}</Text>
              </>
            ) : null}
            . Click the link in the email to activate your account and access your Komi profile.
          </>
        )}
        {activateAccountModalData?.context === MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_LOGIN && (
          <>
           You need to activate your account before you can access your Komi profile by clicking the link in the confirmation email we sent you.
          </>
        )}
      </Paragraph>

      <Paragraph className="opacity--08 m__t--10" preset={isMobile ? "regular14" : "regular16"}>
        Didn’t get the email? Check your spam folder or{" "}
        <span className="text--blue cursor-pointer" onClick={handleResendEmailConfirmation}>
          click here
        </span>{" "}
        to resend it.
      </Paragraph>
    </Modal>
  );
};
