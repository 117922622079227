import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { Button, Input, Spin, Grid, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { ROLES } from "constants/auth";
import { SEGMENT_EVENT } from "constants/segment";
import { validateEmail } from "utils/string";
import { AnalyticServices } from "utils/analytics";
import { useTypedSelector } from "redux/rootReducer";
import { checkUserExistedActions } from "redux/User/actions";
import { selectUserExisted, selectUserLoading } from "redux/User/selector";
import {
  MODAL_LOGIN,
  MODAL_SIGNUP_ENTRY,
  MODAL_SIGNUP_FORM,
  toggleModalActions,
} from "redux/Modal/actions";
import LoginGoogle from "components/LoginGoogle";
import { ReactComponent as RightIcon } from "public/static/assets/icons/sRight.svg";
import { Paragraph, Text } from "../Typography";
import { Interval, Product, ProductKey } from "redux/User/sales";
import { salesService } from "services";

const { useBreakpoint } = Grid;

function trackContinueWithClick(method: "Google" | "Email") {
  AnalyticServices.track(
    SEGMENT_EVENT.CLICK_CONTINUE_WITH,
    { Method: method },
    { source: "talent" },
  );
  AnalyticServices.track(
    SEGMENT_EVENT.GA_CLICK_CONTINUE_WITH,
    { is_ga_only: true, method: method },
    { source: "talent" },
  );
}

export const SignUpEntryContent: React.FC = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const isMobile = screens["xs"];
  const [product, setProduct] = useState<Product | undefined>();
  const [currencyFormatter, setCurrencyFormatter] = useState<Intl.NumberFormat>();

  async function loadProduct() {
    const _product = await salesService.getProduct(ProductKey.PROFILE_BUILDER);
    if (!_product) return;
    setProduct(_product);
    setCurrencyFormatter(
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: _product.prices[Interval.MONTH][0].currency,
      }),
    );
  }

  useEffect(() => {
    loadProduct();
  }, []);

  const [email, setEmail] = useState<string | undefined>(undefined);

  const loading = useTypedSelector(selectUserLoading);
  const isExisted = useTypedSelector(selectUserExisted);

  useEffect(() => {
    if (email?.length && validateEmail(email || "")) {
      dispatch(checkUserExistedActions.REQUEST({ email, role: ROLES.SELFSIGN }));
    }
  }, [dispatch, email]);

  const handleNext = useCallback(() => {
    trackContinueWithClick("Email");

    dispatch(
      toggleModalActions({
        modal: MODAL_SIGNUP_ENTRY,
        status: false,
      }),
    );

    if (isExisted) {
      dispatch(
        toggleModalActions({
          modal: MODAL_LOGIN,
          status: true,
          data: { isUser: true, email, role: ROLES.SELFSIGN },
        }),
      );
    } else {
      dispatch(
        toggleModalActions({
          modal: MODAL_SIGNUP_FORM,
          status: true,
          data: { email },
        }),
      );
    }

    //clear the email at the end of flow
    setEmail("");
  }, [dispatch, isExisted, email]);

  const isDisable = useMemo(
    () => (router?.query?.email?.length || 0) > 0,
    [router?.query?.email?.length],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && !loading && email?.length && validateEmail(email || "")) {
        handleNext();
      }
    },
    [email, handleNext, loading],
  );

  return (
    <Row>
      <Col span={screens["md"] ? 14 : undefined} className="sign-up-modal-entry__content--left">
        <div className="p__y--24">
          <div className="ant-modal-title">
            <Paragraph preset="semibold20">Sign Up for an Account</Paragraph>
            <Paragraph
              className="sign-up-modal-entry__sub-title opacity--08 m__t--4"
              preset={isMobile ? "regular14" : "regular16"}
            >
              Sign up for a Komi account below to access your free trial.
            </Paragraph>
          </div>
        </div>
        <LoginGoogle
          onClick={() => trackContinueWithClick("Google")}
          login_hint={router?.query?.email as string}
          className={"m__t--0"}
          title="Continue with Google"
          operation={ROLES.SELFSIGN}
        />

        <div className="signin-divider">
          <span className="divider"></span>
          <span>or continue with email</span>
          <span className="divider"></span>
        </div>
        <div
          className={classNames({
            "input-email__wrapper": true,
            active: (email?.length || 0) > 0,
          })}
        >
          {/* Render login google component, which redirects to handle oAuth2 */}
          <Input
            autoFocus
            suffix={
              (email?.length || 0) > 0 ? (
                loading ? (
                  <Spin
                    spinning={true}
                    indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                  />
                ) : (
                  <Button
                    className="home-page"
                    data-testid="submit"
                    shape="circle"
                    disabled={!validateEmail(email || "")}
                    onClick={handleNext}
                  >
                    <RightIcon />
                  </Button>
                )
              ) : (
                <span />
              )
            }
            disabled={isDisable}
            className="input-email text__align--center"
            value={email}
            onKeyDown={handleKeyDown}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            placeholder="Enter your email"
          />
        </div>
        <Paragraph className="opacity--08 m__t--24" preset={isMobile ? "regular12" : "regular14"}>
          <Text>
            No payment required to access the free trial. Cancel any time. We&apos;ll remind you 7
            days before the trial ends.
          </Text>
        </Paragraph>
        {product && currencyFormatter ? (
          <Paragraph className="opacity--08 m__t--8" preset={isMobile ? "regular12" : "regular14"}>
            <Text>
              30 days free, then from{" "}
              <strong>
                {currencyFormatter.format(product.prices[Interval.MONTH][0].amount) || 0} / month
              </strong>
              . Sales tax may apply.
            </Text>
          </Paragraph>
        ) : (
          <span data-testid="subscription-summary-loading" />
        )}
        <Row align="middle" className="p__x--12 p__y--8 m__t--24 bg--gray7 bo__radius--8">
          <Paragraph className="leading-22">
            Need to talk? Get in touch with us at{" "}
            <a target="_blank" href="mailto:talent@komi.io" rel="noopener noreferrer">
              <Text className="text--blue">talent@komi.io</Text>
            </a>{" "}
          </Paragraph>
        </Row>
      </Col>
      {screens["md"] && (
        <Col span={10} className="sign-up-modal-entry__content--right">
          <div className="profile-examples"></div>
        </Col>
      )}
    </Row>
  );
};
