import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import CreateAccountModal from "components/CreateAccountModal";
import CreateAccountTeamMemberModal from "components/CreateAccountTeamMemberModal";
import LoginModal from "components/LoginModal";
import SignInModal from "components/SignInModal";
import { SignUpEntryModal, SignUpFormModal } from "components/SignUpModals";
import { SignUpActivateAccountModal } from "components/SignUpModals/SignUpActivateAccountModal";
import TwoFactorModal from "components/TwoFactorModal/TwoFactorModal";
import WaitListModal from "components/WaitListModal";
import WaitlistConfirmModal from "components/WaitListModal/WaitlistConfirmModal";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  MODAL_CREATE_ACCOUNT,
  MODAL_CREATE_ACCOUNT_INVITATION,
  MODAL_LOGIN,
  MODAL_SIGNIN,
  MODAL_SIGNUP_ACTIVATE,
  MODAL_SIGNUP_ENTRY,
  MODAL_SIGNUP_FORM,
  MODAL_TWO_FACTOR,
  MODAL_WAITLIST,
  MODAL_WAITLIST_CONFIRM_MODAL,
  toggleModalActions,
} from "redux/Modal/actions";
import { selectModalData, selectModalStatus } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { setUserValueAction } from "redux/User/actions";

interface IProps {
  isLandingPage?: boolean;
}

const LogginWrapper: React.FC<IProps> = ({ isLandingPage = false }) => {
  const screens = useBreakpoint();
  const isMobile = screens["xs"];

  const dispatch = useDispatch();
  const loginModalStatus = useTypedSelector((state) => selectModalStatus(state, MODAL_LOGIN));
  const modalSigninStatus = useTypedSelector((state) => selectModalStatus(state, MODAL_SIGNIN));
  const createAccountModalStatus = useTypedSelector((state) =>
    selectModalStatus(state, MODAL_CREATE_ACCOUNT),
  );
  const createAccountTeamMemberModalStatus = useTypedSelector((state) =>
    selectModalStatus(state, MODAL_CREATE_ACCOUNT_INVITATION),
  );
  const loginModalData = useTypedSelector((state) => selectModalData(state, MODAL_LOGIN));
  const signUpModalData = useTypedSelector((state) => selectModalData(state, MODAL_CREATE_ACCOUNT));
  const signUpTeamMemberModalData = useTypedSelector((state) => selectModalData(state, MODAL_CREATE_ACCOUNT_INVITATION));
  const isVisibleWaitlist = useTypedSelector((state) => selectModalStatus(state, MODAL_WAITLIST));
  const isVisibleSignupEntry = useTypedSelector((state) =>
    selectModalStatus(state, MODAL_SIGNUP_ENTRY),
  );
  const isVisibleSignupForm = useTypedSelector((state) =>
    selectModalStatus(state, MODAL_SIGNUP_FORM),
  );
  const isVisibleSignupActivate = useTypedSelector((state) =>
    selectModalStatus(state, MODAL_SIGNUP_ACTIVATE),
  );
  const isVisibleWaitlistConfirmModal = useTypedSelector((state) =>
    selectModalStatus(state, MODAL_WAITLIST_CONFIRM_MODAL),
  );
  const isVisibleTwoFactorModal = useTypedSelector((state) =>
    selectModalStatus(state, MODAL_TWO_FACTOR),
  );
  const waitlistConfirmModalData = useTypedSelector((state) =>
    selectModalData(state, MODAL_WAITLIST_CONFIRM_MODAL),
  );
  const twoFactorModalData = useTypedSelector((state) => selectModalData(state, MODAL_TWO_FACTOR));
  const [showEmailLogin, setShowEmailLogin] = React.useState(false);

  const handleCloseWaitlistConfirmModal = useCallback(() => {
    dispatch(
      toggleModalActions({
        modal: MODAL_WAITLIST_CONFIRM_MODAL,
        status: false,
        data: null,
      }),
    );
  }, [dispatch]);

  const toggleModalLogin = useCallback(
    (status: boolean) => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_LOGIN,
          status: status,
          data: signUpModalData || signUpTeamMemberModalData,
        }),
      );
    },
    [dispatch, signUpModalData, signUpTeamMemberModalData],
  );

  const toggleSignInModal = useCallback(
    (status: boolean) => {
    dispatch(
      toggleModalActions({
        modal: MODAL_SIGNIN,
        status: status,
        data: loginModalData,
      }),
    );
  },[dispatch, modalSigninStatus])

  const toggleModalCreateAccount = useCallback(
    (status: boolean) => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT,
          status: status,
          data: loginModalData,
        }),
      );
    },
    [dispatch, loginModalData],
  );
  const toggleModalCreateAccountTeamMember = useCallback(
    (status: boolean) => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      //FIXME remove after createAccount has completely migrated out
      dispatch(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT,
          status: status,
          data: signUpTeamMemberModalData,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT_INVITATION,
          status: status,
          data: loginModalData,
        }),
      );
    },
    [dispatch, loginModalData],
  );
  const toggleModalTwoFactor = useCallback(
    (status: boolean) => {
      dispatch(
        toggleModalActions({
          modal: MODAL_TWO_FACTOR,
          status,
          data: twoFactorModalData,
        }),
      );
    },
    [dispatch, twoFactorModalData],
  );
  const handleCloseWaitlist = () => {
    dispatch(
      toggleModalActions({
        modal: MODAL_CREATE_ACCOUNT,
        status: false,
      }),
    );
    setTimeout(() => {
      dispatch(
        toggleModalActions({
          modal: MODAL_WAITLIST,
          status: false,
        }),
      );
    }, 300);
  };
  const handleCloseSignupEntry = () => {
    dispatch(
      toggleModalActions({
        modal: MODAL_SIGNUP_ENTRY,
        status: false,
      }),
    );
  };
  const handleCloseSignupForm = () => {
    dispatch(
      toggleModalActions({
        modal: MODAL_SIGNUP_FORM,
        status: false,
      }),
    );
  };
  const handleCloseSignUpActivate = () => {
    dispatch(
      toggleModalActions({
        modal: MODAL_SIGNUP_ACTIVATE,
        status: false,
      }),
    );
  };

  return (
    <React.Fragment>
      {/* Modal to handle login */}
      {modalSigninStatus &&
        <SignInModal
        isLandingPage={isLandingPage}
        show={modalSigninStatus}
        onOk={() => toggleSignInModal(false)}
        onCancel={() => {
          toggleSignInModal(false);
          setShowEmailLogin(false);
          dispatch(
            setUserValueAction({
              isRequestJoinTalent: false,
            }),
          );
        }}
        showEmail={showEmailLogin}
      />
      }
      {!!loginModalStatus && (
        <LoginModal
          isLandingPage={isLandingPage}
          show={!!loginModalStatus}
          onOk={() => toggleModalLogin(false)}
          onCancel={() => {
            toggleModalLogin(false);
            setShowEmailLogin(false);
          }}
          showCreateAccountModal={() => {
            toggleModalLogin(false);
            toggleSignInModal(true);
            setShowEmailLogin(false);
          }}
        />
      )}

      {/* CreateAccountModal handles mumbo jumbo logic not covered in the other modals, however most of the logic should be extracted */}
      {createAccountModalStatus && (
        <CreateAccountModal
          isLandingPage={isLandingPage}
          title={"You’re nearly there"}
          description={"Choose a sign up method"}
          show={createAccountModalStatus}
          onOk={() => toggleModalCreateAccount(false)}
          onCancel={() => {
            toggleModalCreateAccount(false);
            setShowEmailLogin(false);
            dispatch(
              setUserValueAction({
                isRequestJoinTalent: false,
              }),
            );
          }}
          showEmail={showEmailLogin}
          showLoginModal={() => {
            toggleModalCreateAccount(false);
            toggleModalLogin(true);
            setShowEmailLogin(false);
          }}
        />
      )}
      {createAccountTeamMemberModalStatus && (
        <CreateAccountTeamMemberModal
          isLandingPage={isLandingPage}
          title={"You’re nearly there"}
          description={"Choose a sign up method"}
          show={createAccountTeamMemberModalStatus}
          onOk={() => toggleModalCreateAccountTeamMember(false)}
          onCancel={() => {
            toggleModalCreateAccountTeamMember(false);
            setShowEmailLogin(false);
            dispatch(
              setUserValueAction({
                isRequestJoinTalent: false,
              }),
            );
          }}
          showEmail={showEmailLogin}
          showLoginModal={() => {
            toggleModalCreateAccountTeamMember(false);
            toggleModalLogin(true);
            setShowEmailLogin(false);
          }}
        />
      )}
      <WaitListModal show={isVisibleWaitlist} onCancel={handleCloseWaitlist} />
      {isVisibleWaitlistConfirmModal && (
        <WaitlistConfirmModal
          title={waitlistConfirmModalData?.title}
          content={waitlistConfirmModalData?.content}
          isMobile={isMobile}
          show={isVisibleWaitlistConfirmModal}
          spacingBtn={waitlistConfirmModalData?.spacingBtn}
          onCancel={handleCloseWaitlistConfirmModal}
        />
      )}
      {isVisibleTwoFactorModal && (
        <TwoFactorModal
          data={twoFactorModalData}
          show={isVisibleTwoFactorModal}
          onCancel={() => toggleModalTwoFactor(false)}
        />
      )}
      <SignUpEntryModal show={isVisibleSignupEntry} onCancel={handleCloseSignupEntry} />
      <SignUpFormModal show={isVisibleSignupForm} onCancel={handleCloseSignupForm} />
      <SignUpActivateAccountModal
        show={isVisibleSignupActivate}
        onCancel={handleCloseSignUpActivate}
      />
    </React.Fragment>
  );
};

export default LogginWrapper;
