import Button from "antd/lib/button";
import Form from "antd/lib/form";
import { Col } from "antd/lib/grid";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import Input from "antd/lib/input";
import notification from "antd/lib/notification";
import Row from "antd/lib/row";
import classNames from "classnames";
import Modal from "components/Modal";
import { useWindowSize } from "hooks/useWindowSize";
import { isValidPhoneNumber } from "libphonenumber-js";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import React, { useCallback, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import {
  MODAL_CREATE_ACCOUNT,
  MODAL_LOGIN,
  MODAL_WAITLIST,
  MODAL_WAITLIST_CONFIRM_MODAL,
  toggleModalActions,
} from "redux/Modal/actions";
import { selectModalData } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { setUserValueAction } from "redux/User/actions";
import { selectUserData } from "redux/User/selector";
import { userService } from "services";
import { Paragraph, Text } from "../Typography";
import WaitlistShareModal from "./WaitlistShareModal";
import { addingHttpsPrefix } from "utils/string";
import { useRouter } from "next/router";

import { SEGMENT_EVENT } from "constants/segment";
import { komiConsumerUrl } from "services/DomainService";
import { KomiLogo } from "@komi-app/components";
import { AnalyticServices } from "utils/analytics";

function trackWaitlistJoined(position: string, waitlistId: string | undefined) {
  const properties = {
    location: AnalyticServices.getLocation(),
    platform: AnalyticServices.getPlatform({ side: "client-side" }),
    position: position,
    waitlistId: waitlistId,
  } as const;

  AnalyticServices.track(SEGMENT_EVENT.JOINED_WAITLIST, {
    Location: properties.location,
    Platform: properties.platform,
    Position: properties.position,
    "Waitlist ID": properties.waitlistId,
  });
  AnalyticServices.track(SEGMENT_EVENT.GA_JOINED_WAITLIST, {
    is_ga_only: true,
    location: properties.location,
    platform: properties.platform,
    position: properties.position,
    waitlist_id: properties.waitlistId,
  });
}

interface IProps {
  closable?: boolean;
  show: boolean;
  onOk?(): void;
  onCancel(): void;
}

enum WaitlistStatus {
  PENDING = "pending",
  REJECTED = "rejected",
  APPROVED = "approved",
}

const WaitListModal: React.FC<IProps> = ({ closable = true, show, onOk, onCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const isMobile = screens["xs"];
  const { height } = useWindowSize();
  const router = useRouter();
  const { ref } = router.query;
  const user = useTypedSelector(selectUserData);

  const signUpModalData = useTypedSelector((state) => selectModalData(state, MODAL_CREATE_ACCOUNT));
  const waitlistModalData = useTypedSelector((state) => selectModalData(state, MODAL_WAITLIST));

  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("start");
  const [showWaitlistShareModal, toggleWaitlistShareModal] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>();

  const handleCloseShareModal = useCallback(
    (status) => {
      show && onCancel();
      toggleWaitlistShareModal(status);
    },
    [show, onCancel, toggleWaitlistShareModal],
  );

  const handleCancel = useCallback(async () => {
    setStatus("start");
    setLoading(false);
    form?.resetFields();
    onCancel();
  }, [onCancel, form]);

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setLoading(true);
        const result: any = await userService.joinWaitlist({
          ...values,
          contactPhone: `+${values.contactPhone}`,
          referralCode: ref || "",
        });
        if (result.ok) {
          form?.resetFields();
          toggleWaitlistShareModal(true);
          setReferralCode(result.response?.referralCode);
          trackWaitlistJoined(waitlistModalData?.position || "", result.response?.id);
          return;
        }
        if (result.kind === "rejected" && result?.data?.data?.status) {
          form?.resetFields();
          switch (result?.data?.data?.status) {
            case WaitlistStatus.PENDING:
              dispatch(
                toggleModalActions({
                  modal: MODAL_WAITLIST_CONFIRM_MODAL,
                  status: true,
                  data: {
                    title: "We Can See You Have Been Added to the Waitlist",
                    content: (
                      <Paragraph
                        className="wait-list-modal__sub-title opacity--08 m__t--8"
                        preset={isMobile ? "regular14" : "regular16"}
                      >
                        We are currently reviewing your request. If approved, onboarding
                        instructions will be sent via email. If you have any questions in the
                        meantime, reach out to{" "}
                        <a target="_blank" href="mailto:support@komi.io" rel="noopener noreferrer">
                          <Text
                            preset={isMobile ? "regular14" : "regular16"}
                            className="text--blue"
                          >
                            support@komi.io.
                          </Text>
                        </a>
                      </Paragraph>
                    ),
                  },
                }),
              );
              break;

            case WaitlistStatus.REJECTED:
              dispatch(
                toggleModalActions({
                  modal: MODAL_WAITLIST_CONFIRM_MODAL,
                  status: true,
                  data: {
                    title: "Your Application Was Rejected",
                    content: (
                      <Paragraph
                        className="wait-list-modal__sub-title opacity--08 m__t--8"
                        preset={isMobile ? "regular14" : "regular16"}
                      >
                        Unfortunately, your application to join Komi was rejected, if you would like
                        to follow up please contact{" "}
                        <a target="_blank" href="mailto:support@komi.io" rel="noopener noreferrer">
                          <Text
                            preset={isMobile ? "regular14" : "regular16"}
                            className="text--blue"
                          >
                            support@komi.io.
                          </Text>
                        </a>
                      </Paragraph>
                    ),
                  },
                }),
              );
              break;
            default:
              dispatch(
                toggleModalActions({
                  modal: MODAL_WAITLIST_CONFIRM_MODAL,
                  status: true,
                  data: {
                    title: "Your Application Has Been Approved",
                    content: (
                      <>
                        <Paragraph
                          className="opacity--08 m__t--8"
                          preset={isMobile ? "regular14" : "regular16"}
                        >
                          You should have received an email invitation. You can now create your Komi
                          account by accepting the invite and going through the sign up process.
                          Once you sign up, you’ll be able to create and edit your new profile.
                        </Paragraph>
                        <Paragraph
                          className=" opacity--08 m__t--8"
                          preset={isMobile ? "regular14" : "regular16"}
                        >
                          {" "}
                          If you haven’t received your invite, please contact{" "}
                          <a
                            target="_blank"
                            href="mailto:support@komi.io"
                            rel="noopener noreferrer"
                          >
                            <Text
                              preset={isMobile ? "regular14" : "regular16"}
                              className="text--blue"
                            >
                              support@komi.io.
                            </Text>
                          </a>
                        </Paragraph>
                      </>
                    ),
                  },
                }),
              );
              break;
          }

          return;
          // eslint-disable-next-line no-constant-condition
        } else if ((result.kind = "rejected" && result?.data?.errorCode === "exist_talent")) {
          dispatch(
            toggleModalActions({
              modal: MODAL_LOGIN,
              status: true,
              data: {
                authProviders: result?.data?.data?.authProviders,
                email: values?.contactEmail,
                isTalent: true,
                isUser: result?.data?.isFinishSetup,
              },
            }),
          );
        }
      } catch (error: any) {
        notification.error({
          message: error.message,
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatch, form, isMobile, waitlistModalData?.position, ref],
  );

  const errorMessage = useCallback(
    (message: string) => {
      return (
        <Row align="top">
          <Text
            preset={"regular12"}
            className={classNames("d--block text--red flex--1 leading-16", isMobile && "pt-3")}
          >
            {message}
          </Text>
        </Row>
      );
    },
    [isMobile],
  );
  const toggleModalLogin = useCallback(() => {
    setTimeout(() => {
      dispatch(
        setUserValueAction({
          error: null,
        }),
      );
      dispatch(
        toggleModalActions({
          modal: MODAL_CREATE_ACCOUNT,
          status: true,
          data: signUpModalData,
        }),
      );
    }, 200);
  }, [dispatch, signUpModalData]);

  const checkPhoneNumber = () => {
    const phone = form.getFieldValue("contactPhone");

    if (!phone?.length) {
      return Promise.reject(errorMessage("Phone number is required"));
    } else {
      if (!isValidPhoneNumber(`+${phone}`)) {
        return Promise.reject(errorMessage("Please enter a valid phone number"));
      }
    }
    return Promise.resolve();
  };
  const renderTitle = () => {
    if (status === "success") {
      return (
        <div>
          <Paragraph preset="semibold20">You are on the Waitlist!</Paragraph>
          <Paragraph
            className="wait-list-modal__sub-title opacity--08 m__t--4"
            preset={isMobile ? "regular14" : "regular16"}
          >
            Your request has been sent. A member of our team will be in touch shortly.
          </Paragraph>
        </div>
      );
    }
    if (status === "exists") {
      return (
        <div>
          <Paragraph className="wait-list-modal__title" preset="semibold20">
            We Can See You Have Been Added to the Waitlist
          </Paragraph>
          <Paragraph
            className="wait-list-modal__sub-title opacity--08 m__t--8"
            preset={isMobile ? "regular14" : "regular16"}
          >
            We are currently reviewing your request. If approved, onboarding instructions will be
            sent via email. If you have any questions in the meantime, reach out to{" "}
            <a target="_blank" href="mailto:support@komi.io" rel="noopener noreferrer">
              <Text preset={isMobile ? "regular14" : "regular16"} className="text--blue">
                support@komi.io.
              </Text>
            </a>
          </Paragraph>
        </div>
      );
    }

    return (
      <div>
        <Paragraph preset="semibold20">Join Waitlist</Paragraph>
        <Paragraph
          className="wait-list-modal__sub-title opacity--08 m__t--4"
          preset={isMobile ? "regular14" : "regular16"}
        >
          Please fill out the form below to join the waitlist.
        </Paragraph>
      </div>
    );
  };

  const handleChangeContactEmail = (event: any) => {
    const contactEmail = event.target.value;
    if (contactEmail?.includes("+")) {
      form.setFieldsValue({ contactEmail: contactEmail.replace(/([+])/g, "") });
    }
  };

  const handleChangeSocialLink = (event: any) => {
    form.setFieldsValue({
      socialProfileLink:
        event.target.value !== "https://" ? addingHttpsPrefix(event.target.value) : "",
    });
  };

  const renderContent = () => {
    if (["success", "exists"].includes(status)) {
      return (
        <>
          <Button
            className={classNames("m__b--8 full-width", { "m__t--16": !isMobile })}
            type="primary"
            onClick={handleCancel}
          >
            DONE
          </Button>
        </>
      );
    }
    return (
      <>
        <Row className="p__x--16 p__y--8 bg--gray7 border-radius-8 m__b--32">
          <Paragraph>
            Working with multiple talent? Contact us at{" "}
            <a target="_blank" href="mailto:talent@komi.io" rel="noopener noreferrer">
              <Text className="text--blue">talent@komi.io</Text>
            </a>{" "}
          </Paragraph>
        </Row>
        <Form layout="vertical" form={form} hideRequiredMark onFinish={handleSubmit}>
          <Form.Item
            className="m__b--16"
            name="publicName"
            label="Talent name"
            rules={[
              {
                required: true,
                message: errorMessage("Please enter your talent name"),
              },
            ]}
          >
            <Input placeholder="Name your audience knows you by" />
          </Form.Item>
          <Form.Item
            className="m__b--16"
            name="socialProfileLink"
            label="Most relevant social profile"
            rules={[
              {
                type: "url",
                message: "This field must be a valid url.",
              },
              {
                required: true,
                message: errorMessage("Please add a social profile link"),
              },
            ]}
          >
            <Input placeholder="Social profile link" onChange={handleChangeSocialLink} />
          </Form.Item>
          <Form.Item
            // validateStatus={isEmailError ? "error" : "success"}
            className="m__b--16"
            name="contactEmail"
            label="Contact email"
            rules={[
              {
                type: "email",
                message: errorMessage("Invalid email address format"),
              },
              {
                required: true,
                message: errorMessage("Please enter a contact email address"),
              },
            ]}
          >
            <Input onChange={handleChangeContactEmail} placeholder="Your email address" />
          </Form.Item>
          <Form.Item
            label="Contact phone number"
            name="contactPhone"
            rules={[{ validator: checkPhoneNumber }]}
          >
            <PhoneInput
              country="us"
              buttonClass="phone-button-select"
              inputClass="phone-input"
              placeholder="Your phone number"
              enableAreaCodes={false}
              onFocus={(e) => {
                e.currentTarget.scrollIntoView();
              }}
            />
          </Form.Item>

          <Button className="m__t--16" type="primary" htmlType="submit" block loading={loading}>
            Join Waitlist
          </Button>
          {!user && (
            <Row
              gutter={4}
              align="middle"
              className={classNames({
                "m__t--16 ": !isMobile,
                "m__t--24": isMobile,
                "p__b--12": !isMobile,
                "p__b--8": isMobile,
              })}
              justify="center"
            >
              <Col>
                <Paragraph preset={isMobile ? "regular14" : "regular16"}>
                  Already have an account?
                </Paragraph>
              </Col>
              <Col className="cursor-pointer" onClick={toggleModalLogin}>
                <Paragraph preset={isMobile ? "regular14" : "regular16"} className="text--blue">
                  Sign In
                </Paragraph>
              </Col>
            </Row>
          )}
        </Form>
      </>
    );
  };
  return (
    <>
      {show && !showWaitlistShareModal && (
        <Modal
          className="wait-list-modal"
          closable={closable}
          title={renderTitle()}
          visible={show}
          onOk={onOk}
          onCancel={handleCancel}
          maskClosable={false}
          footer={
            <Row align="middle" justify="center">
              <KomiLogo height={isMobile ? 20 : 24} />
            </Row>
          }
          closeIcon={<CloseIcon width={isMobile ? 24 : 28} height={isMobile ? 24 : 28} />}
          centered
          width={540}
          bodyStyle={{ maxHeight: isMobile ? (height || 0) - 182 : "unset" }}
        >
          {renderContent()}
        </Modal>
      )}
      {showWaitlistShareModal && (
        <WaitlistShareModal
          signUpLink={`${komiConsumerUrl}/join?ref=${referralCode}`} // TODO: update signUpLink here
          isMobile={isMobile}
          show={showWaitlistShareModal}
          toggleModal={handleCloseShareModal}
        />
      )}
    </>
  );
};

export default WaitListModal;
