export type Product = {
  key: ProductKey;
  name: string;
  price_key: string;
  prices: Record<Interval, Price[]>;
};

export enum ProductKey {
  PROFILE_BUILDER = "profile_builder",
}

export enum Interval {
  MONTH = "month",
  YEAR = "year",
}

export type Price = {
  product_key: ProductKey;
  price_key: string;
  interval: Interval;
  amount: number;
  currency: string;
  tax_included: boolean;
};

export type ProductSuccess = {
  success: true;
  product: Product;
};
