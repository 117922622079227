import React, { ReactNode, useCallback } from "react";
import Modal from "components/Modal";
import Button from "antd/lib/button";
import { Paragraph } from "components/Typography";

import { ReactComponent as CloseFillIcon } from "public/static/assets/icons/close-fill.svg";
import { KomiLogo } from "@komi-app/components";

interface IProps {
  title: string;
  content: ReactNode;
  onCancel(): void;
  onButtonClick?: () => void;
  show: boolean;
  isMobile?: boolean;
  spacingBtn?: number;
}

const WaitlistConfirmModal: React.FC<IProps> = ({
  title,
  content,
  show,
  isMobile,
  onCancel,
  onButtonClick,
  spacingBtn,
}) => {
  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleClickButton = useCallback(() => {
    onCancel();
    onButtonClick?.();
  }, [onButtonClick, onCancel]);

  return (
    <Modal
      className="waitlist-confirmation-modal position--relative"
      visible={show}
      onCancel={handleCancel}
      centered
      width={540}
      closable={false}
      footer={<KomiLogo height={isMobile ? 20 : 24} />}
    >
      <div className={`waitlist-confirmation-modal__header align__items--center `}>
        <CloseFillIcon className="close-icon" onClick={handleCancel} />
        <Paragraph ellipsis={{ rows: 1 }} preset="semibold20">
          {title}
        </Paragraph>
        <div className={isMobile ? "m__t--4" : "m__t--8"}>{content}</div>
        <div className={!isMobile ? "m__t--32" : spacingBtn ? `m__t--${spacingBtn}` : "m__t--24"}>
          <Button className="confirm-btn" onClick={handleClickButton}>
            DONE
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WaitlistConfirmModal;
