import Button from "antd/lib/button";
import notification from "antd/lib/notification";
import Row from "antd/lib/row";
import classNames from "classnames";
import Modal from "components/Modal";
import SignIn from "components/SignIn";
import VerifyEmail from "components/VerifyEmail";
import { useRouter } from "next/router";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import { ReactComponent as LeftIcon } from "public/static/assets/icons/left.svg";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { MODAL_CREATE_ACCOUNT, MODAL_WAITLIST, toggleModalActions } from "redux/Modal/actions";
import { selectModalData } from "redux/Modal/selectors";
import { useTypedSelector } from "redux/rootReducer";
import { selectTalentBasicInfo } from "redux/Talent/selector";
import { setUserValueAction } from "redux/User/actions";
import { selectPhoneOTP, selectSignUpError, selectUserData, selectUserSocial } from "redux/User/selector";
import { PhoneOTP } from "redux/User/types";
import { capitalizeFirstLetter } from "utils/string";
import { Paragraph } from "../Typography";
import { KomiLogo } from "@komi-app/components";

interface IProps {
  mask?: boolean;
  closable?: boolean;
  showEmail?: boolean;
  onOk(): void;
  onCancel(): void;
  isLandingPage?: boolean;
  show: boolean;
}

/**
 * Modal used for sign in, extracted from create account modal
 * only handles logic for login, no exisiting accounts and waitlist
 * scenarios:
 *  - Login from homepage
 *  - No existing email
 *  - No existing gmail
 *  - on Waitlist email
 *  - on Waitlist gmail
 * Aside from this logic uses dispatch in child functions to call other modals
 * @returns 
 */
const SignInModal: React.FC<IProps> = ({
  mask = true,
  onOk,
  onCancel,
  showEmail,
  isLandingPage = false,
  show
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const user = useTypedSelector(selectUserData);
  const signUpError = useTypedSelector(selectSignUpError);
  const userSocial = useTypedSelector(selectUserSocial);
  const phoneOtp: PhoneOTP = useTypedSelector(selectPhoneOTP);

  const [email, setEmail] = useState<string | null>(null);
  const [step, setStep] = useState(showEmail ? 2 : 1);
  const [registerPayload] = useState();
  const signUpModalData = useTypedSelector((state) => selectModalData(state, MODAL_CREATE_ACCOUNT));
  const talentInvitation = useTypedSelector(selectTalentBasicInfo);

  /* Handle moving back and forth */
  const handleBack = useCallback(() => {
    setStep((currentStep) => currentStep - 1);
  }, []);

  const handleNext = useCallback(() => {
    setStep((currentStep) => currentStep + 1);
  }, []);

  const handleSignUpEmail = useCallback(
    (email: string) => {
      setEmail(email);
      handleNext();
    },
    [handleNext],
  );

  const handleCancel = useCallback(async () => {
    dispatch(
      setUserValueAction({
        signUpResult: null,
        userSocial: null,
        error: null,
        phoneOTP: null,
      }),
    );
    onCancel();
  }, [onCancel, dispatch]);
  const handleJoinWaitlist = useCallback(() => {
    dispatch(
      toggleModalActions({
        modal: MODAL_WAITLIST,
        status: true,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const { query } = router;
    const { talentProfileId, token, email, invitation, login_google, newUser } = query as unknown as {
      talentProfileId: string;
      token: string;
      email: string;
      invitation: string;
      login_google: boolean;
      newUser: string;
    };
    if (email && talentProfileId) {
      if (login_google && newUser === "true") {
        setStep(2);
      } else {
        setStep(1);
      }
    }

    return () => {
      if (invitation && email && token) {
        router.replace(window.location?.pathname || "/");
      }
    };
  }, [router]);

  const titleEl = useMemo(() => {
    switch (step) {
      case 1:
        return (
          <div>
            <Paragraph preset="semibold20" className="m__b--4 create-account-modal-title">
              Access Talent Account
            </Paragraph>
            <Paragraph
              preset={isMobile ? "regular14" : "regular16"}
              className={`m__b--0 "create-account-modal-sub-title" opacity--08`}
            >
              Log in to your Komi account to access your talent profile or any other profiles you’ve been invited to.
            </Paragraph>
          </div>
        );

      case 2:
        return (
          <div>
            <>
              <Paragraph preset={isMobile ? "semibold20" : "semibold20"} className="m__b--4 create-account-modal-title">
                <Row align="top" className={`${isMobile && "p__r--40"}`}>
                  <Button type="text" className="m__r--12 p--0 height--24 m__t--4" onClick={handleBack}>
                    <LeftIcon />
                  </Button>
                  <span className="flex--1">It Doesn’t Seem You Have an Account</span>
                </Row>
              </Paragraph>
              <Paragraph
                preset="regular16"
                className={`m__b--0 opacity--08 ${"create-account-modal-sub-title"} ${isMobile && "p__r--0"
                  }`}
              >
                <>
                  <span className="d--block m__b--16 opacity--08">
                    There is no account associated with the email address you’ve entered. At the moment Komi is
                    invite-only. Join waitlist below or ask a team member to invite you to their account.
                  </span>
                  <span className="opacity--08">
                    If you are having problems accesing your existing Komi account, contact{" "}
                  </span>
                  <a className="text--blue" href="mailto:support@komi.io">
                    support@komi.io
                  </a>
                  .
                </>
              </Paragraph>
            </>
          </div>
        );
      case 4:
        return (
          <>
            <Paragraph
              preset={isMobile ? "semibold20" : "semibold20"}
              className={`m__b--4 create-account-modal-title ${isMobile && "p__r--40"}`}
            >
              We Can See You Have Been Added to the Waitlist
            </Paragraph>
            <Paragraph preset="regular16" className={`m__b--0 opacity--08 ${isMobile ? "" : "p__r--52"}`}>
              <span className="opacity--08">
                We are currently reviewing your request. If approved, onboarding instructions will be sent via email. If
                you have any questions in the meantime, reach out to{" "}
              </span>
              <a className="text--blue" href="mailto:support@komi.io">
                support@komi.io
              </a>
            </Paragraph>
          </>
        );
      default:
        break;
    }
  }, [
    step,
    isLandingPage,
    talentInvitation,
    handleBack,
    signUpModalData?.isApproved,
    signUpModalData?.isUser,
  ]);

  useEffect(() => {
    if (user) {
      onOk();
    }
  }, [onOk, user]);

  useEffect(() => {
    if (signUpError) {
      notification.error({
        message: capitalizeFirstLetter(signUpError),
      });
    }
  }, [signUpError]);

  useEffect(() => {
    if (phoneOtp) {
      setStep(3);
    }
  }, [phoneOtp]);

  useEffect(() => {
    if ((userSocial || signUpModalData?.callback) && process.browser) {
      setStep(2);
    }
  }, [signUpModalData?.callback, userSocial]);

  useEffect(() => {
    if (user && signUpModalData?.callback) {
      signUpModalData?.callback();
    }
  }, [signUpModalData, user]);


  return (
    <Modal
      maskStyle={{ background: `rgb(18, 18, 18)` }}
      className={classNames("create-account-modal home-page")}
      closable={true}
      maskClosable={false}
      mask={mask}
      title={titleEl}
      visible={show}
      onOk={onOk}
      footer={
        <>
          <Row align="middle" justify="center">
            <KomiLogo height={isMobile ? 20 : 24} />
          </Row>
        </>
      }
      onCancel={handleCancel}
      width={558}
      centered
      closeIcon={<CloseIcon />}
      destroyOnClose
    >
      {step === 1 && (
        <SignIn
          isHomePage={isLandingPage}
          //setRegistrationMethod={setRegistrationMethod}
          handleSignUpEmail={handleSignUpEmail}
          setStep={setStep}
        />
      )}
      {step === 2 &&
        <>
          <Button
            className={`btn-signin home-page`}
            type="primary"
            htmlType="submit"
            block
            onClick={handleJoinWaitlist}
          >
            Join Waitlist
          </Button>
        </>
      }
      {step === 3 && <VerifyEmail registerPayload={registerPayload} />}
      {step === 4 && (
        <Button className={`btn-signin home-page`} type="primary" htmlType="submit" block onClick={onCancel}>
          Done
        </Button>
      )}
    </Modal>
  );
};

export default SignInModal;
