import React, { useCallback } from "react";
import { Grid } from "antd";

import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import { ReactComponent as CloseIconInverted } from "public/static/assets/icons/close-modal-inverted.svg";
import Modal from "components/Modal";

import { SignUpEntryContent } from "./SignUpEntryContent";

const { useBreakpoint } = Grid;

interface IProps {
  closable?: boolean;
  show: boolean;
  onOk?(): void;
  onCancel(): void;
}

export const SignUpEntryModal: React.FC<IProps> = ({ closable = true, show, onOk, onCancel }) => {
  const screens = useBreakpoint();
  const isMobile = screens["xs"];

  const handleCancel = useCallback(async () => {
    onCancel();
  }, [onCancel]);

  if (!show) return null;

  const closeIcon = () => {
    const dimension = isMobile ? 24 : 28;
    const props = {
      width: dimension,
      height: dimension,
    };

    if (screens["md"]) {
      return <CloseIconInverted {...props} />;
    }

    return <CloseIcon {...props} />;
  };

  return (
    <Modal
      className="sign-up-modal sign-up-modal-entry"
      closable={closable}
      visible={show}
      onOk={onOk}
      onCancel={handleCancel}
      maskClosable={false}
      closeIcon={closeIcon()}
      centered
      footer={null}
    >
      <SignUpEntryContent />
    </Modal>
  );
};
