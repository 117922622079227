import React, { useCallback, useMemo } from "react";
import Modal from "components/Modal";
import Button from "antd/lib/button";
import { Paragraph, Text } from "components/Typography";
import Alert from "antd/lib/alert";
import { KomiLogo } from "@komi-app/components";
import { ReactComponent as CloseFillIcon } from "public/static/assets/icons/close-fill.svg";
import { ReactComponent as FacebookIcon } from "public/static/assets/icons/facebook_bw.svg";
import { ReactComponent as TwitterIcon } from "public/static/assets/icons/twitter_bw.svg";
import { ReactComponent as WhatsAppIcon } from "public/static/assets/icons/whatsapp_bw.svg";
import { ReactComponent as MailIcon } from "public/static/assets/icons/mail_bw.svg";
import { copyStringToClipboard } from "utils/clipboard";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from "react-share";

interface IProps {
  signUpLink: string;
  toggleModal(show: boolean): void;
  onButtonClick?: () => void;
  show: boolean;
  isMobile?: boolean;
}

const WaitlistShareModal: React.FC<IProps> = ({ signUpLink, show, isMobile, toggleModal, onButtonClick }) => {
  const handleCancel = useCallback(() => {
    toggleModal(false);
  }, [toggleModal]);

  const handleClickButton = useCallback(() => {
    toggleModal(false);
    onButtonClick?.();
  }, [onButtonClick, toggleModal]);

  const shareContent = useMemo(() => {
    return `I’ve just joined the waitlist for a link-in-bio tool called Komi - the ultimate destination for your audience. Use this link to sign up: ${signUpLink}`;
  }, [signUpLink]);

  const handleCopyCode = useCallback(() => {
    copyStringToClipboard(shareContent);
  }, [shareContent]);

  return (
    <Modal
      className="waitlist-share-modal position--relative"
      visible={show}
      onCancel={handleCancel}
      centered
      width={540}
      closable={false}
      footer={<KomiLogo height={isMobile ? 20 : 24} />}
    >
      <div className="waitlist-share-modal__header align__items--center">
        <Alert
          message="Your request to join Komi has been submitted. If approved, onboarding instructions will be sent via email."
          type="success"
          showIcon
        />
        <CloseFillIcon className="close-icon" onClick={handleCancel} />
        <Paragraph preset="semibold20" className="m__t--24 text--default">
          Invite Your Friends
        </Paragraph>
        <Paragraph
          preset={isMobile ? "regular14" : "regular16"}
          className={`text--default ${isMobile ? "m__t--4" : "m__t--8"}`}
        >
          Invite at least <Text preset={isMobile ? "semibold14" : "semibold16"}>two friends</Text> and you will get
          priority access*. You can share by forwarding the message below or by clicking on one of the social options.
        </Paragraph>
        <div className="waitlist-share-modal__copy--btn d--flex p--12 m__t--16">
          <Paragraph preset="regular14">
            I’ve just joined the waitlist for a link-in-bio tool called Komi - the ultimate destination for your
            audience. Use this link to sign up:{" "}
            <a
              className="sign-up-link"
              href={signUpLink?.includes("http") ? signUpLink : `https://${signUpLink}`}
              target="_blank"
              rel="noreferrer"
            >
              {signUpLink}
            </a>
          </Paragraph>
          <div className="copy-text cursor-pointer" onClick={handleCopyCode}>
            Copy
          </div>
        </div>
        <div className="social-button-group d--flex">
          <FacebookShareButton url={signUpLink} quote={shareContent}>
            <Button className="social-btn">
              <FacebookIcon />
            </Button>
          </FacebookShareButton>
          <TwitterShareButton url={shareContent}>
            <Button className="social-btn">
              <TwitterIcon />
            </Button>
          </TwitterShareButton>
          <WhatsappShareButton url={shareContent}>
            <Button className="social-btn">
              <WhatsAppIcon />
            </Button>
          </WhatsappShareButton>
          <EmailShareButton url={shareContent}>
            <Button className="social-btn">
              <MailIcon />
            </Button>
          </EmailShareButton>
        </div>
        <div className={isMobile ? "m__t--24" : "m__t--32"}>
          <Button className="confirm-btn" onClick={handleClickButton}>
            DONE
          </Button>
        </div>
        <div className="text__align--center m__t--16">
          *Priority access is subject to our{" "}
          <a href="/talent-terms" target="_blank">
            terms and conditions
          </a>
          .
        </div>
      </div>
    </Modal>
  );
};

export default WaitlistShareModal;
