import React, { useCallback, useEffect, useMemo, useState, createRef, RefObject } from "react";
import { Row, Grid, Form, Input, Button, Checkbox } from "antd";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { useTypedSelector } from "redux/rootReducer";
import { selectModalData } from "redux/Modal/selectors";
import {
  MODAL_SIGNUP_ACTIVATE,
  MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_SIGNUP,
  MODAL_SIGNUP_FORM,
  toggleModalActions,
} from "redux/Modal/actions";
import { selectUserId, selectUserLoading, selectUserSocial } from "redux/User/selector";
import { ReactComponent as CloseIcon } from "public/static/assets/icons/close-modal.svg";
import { ReactComponent as ErrorIcon } from "public/static/assets/icons/notification/error.svg";
import { useWindowSize } from "hooks/useWindowSize";
import { checkConfirmPassword, checkFullName, checkPassword } from "utils/validators";
import Modal from "components/Modal";
import DatePicker from "components/DatePicker";
import { Paragraph, Text } from "components/Typography";
import { useRouter } from "next/router";
import { userService } from "services";
import { ROLES } from "constants/auth";
import { setUserValueAction, talentLoginWithGoogleActions } from "redux/User/actions";
import { AnalyticServices } from "utils/analytics";
import { SEGMENT_EVENT } from "constants/segment";
import { Api } from "services/api";

const { useBreakpoint } = Grid;

interface FormProperties {
  email?: string;
  fullName: string;
  password: string;
  confirmPassword: string;
  birthday: {
    month: number;
    day: number;
    year: number;
  };
  termAndMarketing: boolean;
}

interface IProps {
  closable?: boolean;
  show: boolean;
  onOk?(): void;
  onCancel(): void;
}

function trackSignupAttempt(track: { method: "Google" | "Email"; successful: boolean }) {
  const properties = {
    isSuccessful: track.successful ? "Yes" : "No",
    method: track.method,
  } as const;

  AnalyticServices.track(
    SEGMENT_EVENT.SIGN_UP_ATTEMPT,
    {
      "Is successful?": properties.isSuccessful,
      Method: properties.method,
    },
    { source: "talent" },
  );
  AnalyticServices.track(
    SEGMENT_EVENT.GA_SIGN_UP_ATTEMPT,
    {
      is_ga_only: true,
      is_successful: properties.isSuccessful,
      method: properties.method,
    },
    { source: "talent" },
  );
}

export const SignUpFormModal: React.FC<IProps> = ({ closable = true, show, onOk, onCancel }) => {
  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const isMobile = screens["xs"];
  const { height } = useWindowSize();
  const [form] = Form.useForm<FormProperties>();

  const recaptchaRef = createRef() as RefObject<any>;

  //grab oAuth login
  const router = useRouter();

  const [isValidDateOfBirth, setIsValidDateOfBirth] = useState(false);
  const [errorDateOfBirth, setErrorDateOfBirth] = useState(false);

  const loginModalData: { email?: string } | null = useTypedSelector((state) =>
    selectModalData(state, MODAL_SIGNUP_FORM),
  );
  const userLoading = useTypedSelector(selectUserLoading);

  //handle errors
  const [signUpError, setSignupError] = useState<boolean>(false);
  const [signUpLoading, setSignUpLoading] = useState(false);
  const googleLogin = useTypedSelector(selectUserSocial);
  const userId = useSelector(selectUserId);

  useEffect(() => {
    form.setFieldsValue({ email: loginModalData?.email });
  }, [form, loginModalData?.email]);

  const handleCancel = useCallback(async () => {
    form.resetFields();
    dispatch(setUserValueAction({ userSocial: null, oAuth: null }));
    onCancel();
  }, [onCancel, form]);

  /* extract google account from query params */
  const userGoogleSignup = useMemo(() => {
    //grab the query params
    const { operation } = router.query;
    const userGoogleSignup = operation === ROLES.SELFSIGN ? googleLogin : false;

    //clear query params
    if (userGoogleSignup) {
      router.replace(window.location?.pathname || "/", undefined, { shallow: true });
    }

    return userGoogleSignup;
  }, [googleLogin]);

  const renderTitle = () => {
    return (
      <div>
        <div className="d--flex">
          <Paragraph preset="semibold20">You’re nearly there!</Paragraph>
        </div>
        <Paragraph
          className="sign-up-modal__sub-title opacity--08 m__t--4"
          preset={isMobile ? "regular14" : "regular16"}
        >
          Sign up for a Komi account below to access your free trial.
        </Paragraph>
      </div>
    );
  };

  const errorMessage = useCallback(
    (message: string) => (
      <Row align="top">
        <Text
          preset={"regular12"}
          className={classNames("d--block text--red flex--1 leading-16", isMobile && "pt-3")}
        >
          {message}
        </Text>
      </Row>
    ),
    [isMobile],
  );

  /**
   * Util function for parsing google full name
   * @returns string
   */
  const googleFullName = () => {
    return userGoogleSignup.firstName //if the first name is retrieved
      ? userGoogleSignup.firstName +
      (userGoogleSignup.lastName ? " " + userGoogleSignup.lastName : "") //format first and last
      : ""; //otherwise return empty string
  };

  const handleSubmit = async () => {
    //google captha
    const googleRecaptchaToken = await recaptchaRef.current.executeAsync();

    //validate fields
    await form.validateFields();

    if (!isValidDateOfBirth) {
      setErrorDateOfBirth(true);
      return;
    }

    //set creds
    let credentials: { method: "Google" | "Email"; email: string; providerId: string };
    if (userGoogleSignup && userGoogleSignup.email) {
      credentials = {
        method: "Google",
        email: userGoogleSignup.email,
        providerId: userGoogleSignup?.authProviderId || "",
      };
    } else if (loginModalData && loginModalData.email) {
      credentials = {
        method: "Email",
        email: loginModalData.email,
        providerId: "",
      };
    } else {
      //if email is empty and google email was not used
      return;
    }

    //extract values
    const formResult = form.getFieldsValue();
    const dob = formResult.birthday;
    const dateOfBirth: Date = new Date(dob.year, dob.month, dob.day);

    try { //create and sign in user

      //request to create user
      setSignUpLoading(true);
      const apiResponse = await userService.selfSignUp({
        firstName: "",
        lastName: formResult.fullName,
        email: credentials.email,
        password: formResult.password,
        dateOfBirth: dateOfBirth.toISOString(),
        authProviderId: credentials.providerId,
        googleRecaptchaToken,
      });

      setSignUpLoading(false);
      const error = !Api.isOKResponse(apiResponse);
      setSignupError(error);

      trackSignupAttempt({ method: credentials.method, successful: !error });

      if (error) return;

      form.resetFields();

      //launch activate email modal for non oAuth signups
      if (credentials.method === "Email") {
        dispatch(
          toggleModalActions({
            modal: MODAL_SIGNUP_ACTIVATE,
            status: true,
            data: {
              name: formResult.fullName,
              userId: apiResponse.response.id,
              email: credentials.email,
              activationCodeVersion: apiResponse.response.activationCodeVersion,
              context: MODAL_SIGNUP_ACTIVATE_CONTEXT_POST_SIGNUP,
            },
          }),
        );
      } else {
        //close the modal and login in the newly created user using the oAuth flow
        dispatch(
          toggleModalActions({
            modal: MODAL_SIGNUP_FORM,
            status: false,
          }),
        );

        dispatch( //login in the newly created user
          talentLoginWithGoogleActions.REQUEST({
            token: googleLogin.accessToken,
            operation: "selfsign",
            onSuccessLogin: () => router.push("/create-profile") //redirect them to create profile after login
          })
        );
      }
    } catch (error) {
      //display error alert in modal
      setSignupError(true);
      trackSignupAttempt({ method: credentials.method, successful: false });
      console.log({ error });

      // reset recaptcha challenge
      recaptchaRef.current.reset();
    }
  };

  const checkTermsChecked = (_: any, checked: boolean) => {
    if (checked) return Promise.resolve();
    const err = new Error(
      "In order to continue you must first accept Komi’s terms of service and acknowledge Komi’s privacy policy",
    );
    return Promise.reject(err);
  };

  if (!show) return null;

  const isLoading = userLoading || signUpLoading;

  return (
    <Modal
      className="sign-up-modal"
      closable={closable}
      title={renderTitle()}
      visible={show}
      onOk={onOk}
      onCancel={handleCancel}
      maskClosable={false}
      closeIcon={<CloseIcon width={isMobile ? 24 : 28} height={isMobile ? 24 : 28} />}
      centered
      width={540}
      bodyStyle={{ maxHeight: isMobile ? (height || 0) - 182 : "unset" }}
      footer={null}
    >
      <Form
        layout="vertical"
        form={form}
        hideRequiredMark
        onFinish={handleSubmit}
        initialValues={{ fullName: googleFullName() }} //render initial value for name, when google is used
      >
        {!userGoogleSignup ? (
          <Form.Item className="m__b--16" name="email" label="Email">
            <Input value={loginModalData?.email} type="email" disabled />
          </Form.Item>
        ) : null}

        <Form.Item
          className="m__b--16"
          name="fullName"
          label="Name"
          rules={[{ validator: checkFullName(form, "fullName", errorMessage) }]}
        >
          <Input placeholder="Enter your full name" disabled={isLoading} />
        </Form.Item>

        {!userGoogleSignup && ( //only render password on non google signup
          <>
            <Form.Item
              className="m__b--16"
              name="password"
              label="Set a password"
              rules={[{ validator: checkPassword(form, "password", errorMessage) }]}
            >
              <Input
                type="password"
                placeholder="Enter 12 characters or more"
                disabled={isLoading}
              />
            </Form.Item>

            <Form.Item
              className="m__b--16"
              name="confirmPassword"
              label="Confirm password"
              rules={[
                {
                  validator: checkConfirmPassword(
                    form,
                    "password",
                    "confirmPassword",
                    errorMessage,
                  ),
                },
              ]}
            >
              <Input type="password" placeholder="Re-enter your password" disabled={isLoading} />
            </Form.Item>
          </>
        )}

        <DatePicker
          form={form}
          errorMessage={errorMessage}
          onValidate={setIsValidDateOfBirth}
          isError={errorDateOfBirth}
          onChangeError={setErrorDateOfBirth}
          label="Date of birth"
          checkAge={18}
          disabled={isLoading}
        />

        <Form.Item
          name="termAndMarketing"
          valuePropName="checked"
          rules={[{ validator: checkTermsChecked }]}
        >
          <Checkbox className="checkbox-inline" disabled={isLoading}>
            <Text preset="regular14">
              <span>I agree to Komi’s </span>
              <a
                href={`${process.env.NEXT_PUBLIC_URL}/talent-terms`}
                target="_blank"
                rel="noreferrer"
              >
                talent terms
              </a>
              <span> and acknowledge Komi’s </span>
              <a
                href={`${process.env.NEXT_PUBLIC_URL}/privacy-policy`}
                target="_blank"
                rel="noreferrer"
              >
                privacy policy
              </a>
            </Text>
          </Checkbox>
        </Form.Item>

        {signUpError ? (
          <div className="error-container">
            <ErrorIcon />
            <Paragraph preset="regular16">
              There was an error submitting the form. Please try again.
            </Paragraph>
          </div>
        ) : null}

        {process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHAV2_SITE_KEY && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHAV2_SITE_KEY}
          />
        )}

        <Form.Item className={`m__b--0 ${isMobile ? "m__t--30" : "m__t--20"}`}>
          <Button
            className={`btn-sign-up ant-btn-md`}
            type="primary"
            htmlType="submit"
            block
            loading={isLoading}
          >
            Sign up
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
